import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layout/BasicLayout.vue'),
    children: [
      {
        path: '/product',
        name: 'ProductPage',
        component: () => import('@/views/ProductPage.vue')
      },
      {
        path: '/fix',
        name: 'Fix',
        component: () => import('@/views/FixPage.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/AboutPage.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/NewsPage.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/ContactPage.vue')
      },
      {
        path: '/recruit',
        name: 'Recruit',
        component: () => import('@/views/RecruitPage.vue')
      },
      {
        path: '/mobile/product',
        name: 'MobileProduct',
        component: () => import('@/views/MobileProductPage.vue')
      },
      {
        path: '/mobile/fix',
        name: 'MobileFix',
        component: () => import('@/views/MobileFixPage.vue')
      },
      {
        path: '/mobile/about',
        name: 'MobileAbout',
        component: () => import('@/views/MobileAboutPage.vue')
      },
      {
        path: '/mobile/news',
        name: 'MobileNews',
        component: () => import('@/views/MobileNewsPage.vue')
      },
      {
        path: '/mobile/contact',
        name: 'MobileContact',
        component: () => import('@/views/MobileContactPage.vue')
      },
      {
        path: '/mobile/recruit',
        name: 'MobileRecruit',
        component: () => import('@/views/MobileRecruitPage.vue')
      }
    ]
  },
  {
    path: '/admin',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/Index'),
    redirect: '/index/user',
    children: [
      {
        path: '/index/user',
        name: 'User',
        component: () => import('@/views/user/User.vue')
      },
      {
        path: '/index/type',
        name: 'Type',
        component: () => import('@/views/type/Type.vue')
      },
      {
        path: '/index/product',
        name: 'Product',
        component: () => import('@/views/product/Product.vue')
      },
      {
        path: '/index/company',
        name: 'Company',
        component: () => import('@/views/company/Company.vue')
      },
      {
        path: '/index/picture',
        name: 'Picture',
        component: () => import('@/views/picture/Picture.vue')
      },
      {
        path: '/index/about',
        name: 'AboutPage',
        component: () => import('@/views/about/About.vue')
      },
      {
        path: '/index/news',
        name: 'NewsPage',
        component: () => import('@/views/news/News.vue')
      },
      {
        path: '/index/position',
        name: 'PositionPage',
        component: () => import('@/views/position/Position.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
