import Vue from 'vue'
import { Aside, Button, ButtonGroup, Breadcrumb, BreadcrumbItem,Collapse, CollapseItem, Container, Carousel, CarouselItem, Card, Dialog, Form, FormItem, Header,
  Input, Main, Menu, MenuItem, Notification, Option, Popover, Popconfirm, Switch, Select, Table, TableColumn, Upload } from 'element-ui'

Vue.use(Aside)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Container)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Card)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Header)
Vue.use(Input)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Popconfirm)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Upload)

Vue.prototype.$notify = Notification
